// i18next-extract-mark-ns-start credit-cards

//Payment methods
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';

//Sections
//Images
import cards_monei from 'images/cards_monei.svg';

import React from 'react';
import styled from 'styled-components';
import {AnchorLink} from 'components/AnchorLink';
import {IndexBackground} from 'components/landings/IndexBackground';
import {MainTitleImg} from 'components/payment-methods/MainTitleImg';
import {MainTitleContent} from 'components/payment-methods/MainTitleContent';
import {BlogLink} from 'components/links/Blog';
import {InternalPageLink} from 'components/links/Pages';
import {HomePaymentMethods, Logo} from 'components/HomePaymentMethods';
import {Button} from 'components/Button';
import {Card} from 'components/Card';
import {List} from 'components/List';
import {SupportLink} from 'components/links/Support';
import {PrismicPage} from 'components/links/PrismicPage';
import Bold from 'components/Bold';
import {AndroidDownloadLink} from 'components/DownloadOnGooglePlay';
import {IosDownloadLink} from 'components/DownloadOnAppleStore';
import {Partners} from 'components/Partners';
import moneiPayFormEn from 'images/monei_pay_form_en.png';
import moneiPayFormEs from 'images/monei_pay_form_es.png';
import bancontact from 'images/bancontact.svg';
import {FaqsSection} from 'components/FaqsSection';
import {RelatedBlogPosts} from 'components/RelatedBlogPosts';
import { mapCategory } from 'utils/blog-utils';
import { BlogPost } from '../../../types/BlogTypes';
import { BlogCategoryType } from '../../../types/BlogTypes';
import { getPostsFromPrismicEdges } from 'utils/blog-utils';

const PaymentCard = styled(Card)`
  padding: 32px 62px;
  flex-basis: 100%;
  margin-top: 30px;

  @media (max-width: 768px) {
    margin-top: 0;
    padding: 40px 32px;
  }
`;

const LargeSectionHeader = styled(SectionHeader)`
  h1 {
    font-size: 2.625rem;
    line-height: 3.25rem;

    @media (max-width: 768px) {
      font-size: 2rem;
      line-height: 2.75rem;
    }
  }
`;

const CreditCards: React.FC = (props: any) => {
  const {t, language, navigate} = useI18next();
  const moneiPayForm = ['es', 'ca'].includes(language) ? moneiPayFormEs : moneiPayFormEn;
  const extraLogos: Logo[] = [
    {
      src: bancontact,
      slug: 'payment-methods/bancontact',
      alt: 'Bancontact',
      height: 50
    }
  ];

  const faqs = [
    {
      header: t('Which credit cards does MONEI support?'),
      text: t(
        'With MONEI’s payment gateway platform, you can accept both national and international credit card payments via cards issued by Visa, Mastercard, JCB, Diners Club International, Discover, and Union Pay.'
      ),
      content: t(
        'With MONEI’s payment gateway platform, you can accept both national and international credit card payments via cards issued by Visa, Mastercard, JCB, Diners Club International, Discover, and Union Pay.'
      )
    },
    {
      header: t('How do I activate the credit card payment method?'),
      text: t(
        "The process to activate credit cards in your account depends on your plan: - MONEI X or MONEI Pay: credit card payments will be configured automatically once your account has been approved. - MONEI PLUS: If you'd like to upgrade to the MONEI PLUS plan so you can set up payment routing rules, you must have your own TPV Virtual."
      ),
      content: (
        <Trans>
          The process to activate credit cards in your account depends on{' '}
          <InternalPageLink slug="pricing">your plan:</InternalPageLink> - MONEI X or MONEI Pay:
          credit card payments will be configured automatically once your account has been approved.
          - MONEI PLUS: If you'd like to upgrade to the MONEI PLUS plan so you can set up payment
          routing rules, you must have your own{' '}
          <InternalPageLink slug="tpv-virtual">TPV Virtual.</InternalPageLink>
        </Trans>
      )
    },
    {
      header: t('What are the transaction fees for credit card payments?'),
      text: t(
        'Credit card fees range depending on the MONEI plan you choose. For more information about MONEI X, MONEI PLUS, and MONEI Pay, see our pricing page.'
      ),
      content: (
        <Trans>
          Credit card fees range depending on the MONEI plan you choose. For more information about
          MONEI X, MONEI PLUS, and <InternalPageLink slug="monei-pay">MONEI Pay</InternalPageLink>,
          see our <InternalPageLink slug="pricing">pricing page.</InternalPageLink>
        </Trans>
      )
    },
    {
      header: t('How are credit card transactions paid out to me?'),
      text: t(
        "Credit card transaction payouts depend on the current MONEI plan you're using. - With MONEI X, we settle payments into the IBAN you've added to your MONEI account. - With MONEI PLUS, your acquiring bank will settle payments into your IBAN.- With MONEI Pay, we settle payments into the IBAN you've added to your MONEI account."
      ),
      content: (
        <Trans>
          Credit card transaction payouts depend on the current{' '}
          <InternalPageLink slug="pricing">MONEI plan</InternalPageLink> you're using. - With MONEI
          X, we settle payments into the IBAN you've added to your MONEI account. - With MONEI PLUS,
          your acquiring bank will settle payments into your IBAN.- With MONEI Pay, we settle
          payments into the IBAN you've added to your MONEI account.
        </Trans>
      )
    }
  ];
  const {data} = props;

  const categories: BlogCategoryType[] = data.prismic.allCategorys.edges.map(mapCategory) || [];
  const popularPosts: BlogPost[] = getPostsFromPrismicEdges({
    posts: data.popularPosts.allPosts.edges,
    categories
  });

  return (
    <>
      <SEO
        title={t('Accept Credit Card Payments Online & In Person')}
        description={t(
          'Increase sales and customer satisfaction by accepting credit cards online, in-store, at your restaurant, or anywhere your business takes you. Start here ››'
        )}
      />
      <IndexBackground
        sx={{
          left: {all: '108%', xl: '119%', lg: '1325px'},
          top: {all: '-100%', xl: '-108%', lg: '-125%'},
          height: {all: '2150px', lg: '1960px'},
          width: {all: '1530px'},
          transform: {
            all: 'rotate(-58deg) skew(0deg, 17deg)',
            xl: 'rotate(-58deg) skew(2deg,33deg)',
            lg: 'rotate(-58deg) skew(12deg,12deg)'
          }
        }}>
        <Content>
          <Section reverseOnMobile>
            <MainTitleContent sx={{maxWidth: {all: '590px', md: '100%'}}}>
              <LargeSectionHeader underline tagName="h1">
                <Trans>Accept credit cards online and in person</Trans>
              </LargeSectionHeader>
              <Trans parent="p">
                Credit cards are still one of the most popular payment methods. To accept credit
                cards online or in person, you need a payment service provider (PSP) that lets you
                safely process transactions from popular card networks like Visa, Mastercard, and
                more. Get started with MONEI to take card payments via your website or in person
                from your phone today.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </MainTitleContent>
            <MainTitleImg
              src={cards_monei}
              alt="MONEI payment gateway"
              title="MONEI payment gateway"
            />
          </Section>
        </Content>
      </IndexBackground>

      <Content>
        <Section column>
          <SectionHeader sx={{textAlign: 'center'}}>
            <Trans>
              Accept credit cards and more online, at your retail store, restaurant, or on the go
              with MONEI
            </Trans>
          </SectionHeader>

          <Trans parent="p" style={{textAlign: 'center'}}>
            Accepting credit card payments is crucial, but there are many important options to add
            to your payment stack. Use a single connection to accept card payments and{' '}
            <BlogLink slug="alternative-payment-methods">alternative payment methods</BlogLink>{' '}
            including{' '}
            <BlogLink slug="digital-wallet-ecommerce">digital and mobile wallets</BlogLink> like{' '}
            <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>,{' '}
            <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink>,{' '}
            <InternalPageLink slug="payment-methods/paypal">PayPal</InternalPageLink>, and{' '}
            <InternalPageLink slug="payment-methods/click-to-pay">Click to Pay</InternalPageLink>.
            As well as <BlogLink slug="local-payment-methods">local payment methods</BlogLink> like{' '}
            <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink>,
            <InternalPageLink slug="payment-methods/bancontact">Bancontact</InternalPageLink>,{' '}
            <InternalPageLink slug="payment-methods/multibanco">Multibanco</InternalPageLink>,{' '}
            <InternalPageLink slug="payment-methods/sepa-direct-debit">
              SEPA Direct Debit
            </InternalPageLink>{' '}
            and{' '}
            <InternalPageLink slug="payment-methods/sepa-request-to-pay">
              SEPA Request-to-Pay
            </InternalPageLink>
            .
          </Trans>
          <PaymentCard>
            <HomePaymentMethods extraLogos={extraLogos} />
            <Button variant="dark" onClick={() => navigate('/payment-methods/')}>
              <Trans>View All Payment Methods</Trans>
            </Button>
          </PaymentCard>
        </Section>
      </Content>

      <Background>
        <Content>
          <Section centered>
            <SectionImage
              src={moneiPayForm}
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              height={650}
            />
            <div>
              <SectionHeader>
                <Trans>Why choose MONEI?</Trans>
              </SectionHeader>
              <Trans parent="p">
                Choosing the right{' '}
                <InternalPageLink slug="omnichannel-payments-platform">
                  omnichannel payments platform
                </InternalPageLink>{' '}
                is vital to the success of your business. Using a PSP that offers{' '}
                <BlogLink slug="what-is-tokenization-and-its-benefits-for-e-commerce">
                  credit card tokenization
                </BlogLink>{' '}
                guarantees secure card transactions. And thanks to{' '}
                <InternalPageLink slug="monei-pay">MONEI Pay</InternalPageLink>, you can experiment
                with physical retail, take card payments at your restaurant, or charge for your
                services all from your phone. Here are some more benefits:
              </Trans>
              <List>
                <Trans parent="li">Increase customer satisfaction and sales</Trans>
                <Trans parent="li">
                  Improve your transaction approval rates, reduce abandoned carts, and boost
                  conversions with{' '}
                  <InternalPageLink slug="features/payments-orchestration">
                    payments orchestration
                  </InternalPageLink>
                </Trans>
                <Trans parent="li">
                  Grow your business{' '}
                  <BlogLink slug="cross-border-ecommerce">internationally</BlogLink> by accepting{' '}
                  <BlogLink slug="cross-border-payments">cross-border payments</BlogLink>
                </Trans>
                <Trans parent="li">
                  Boost brand awareness and build trust by customizing your{' '}
                  <AnchorLink href="https://docs.monei.com/docs/integrations/build-custom-checkout/">
                    checkout page
                  </AnchorLink>{' '}
                  (online) or{' '}
                  <SupportLink slug="/articles/8797620433937">mobile payment page</SupportLink> (in
                  person)
                </Trans>
                <Trans parent="li">
                  Use your logo, brand colors, and{' '}
                  <SupportLink slug="/articles/360017953818">your domain</SupportLink> throughout
                  the online payment process so the customer never leaves your website
                </Trans>
                <Trans parent="li">
                  Reduce customer queues for in-person sales and save money on POS hardware by
                  accepting contactless{' '}
                  <InternalPageLink slug="monei-pay/qr">QR code payments</InternalPageLink> from
                  your phone
                </Trans>
              </List>
            </div>
          </Section>
        </Content>
      </Background>

      <Content>
        <Section centered column style={{maxWidth: '940px', margin: 'auto', textAlign: 'center'}}>
          <SectionHeader underline>
            <Trans>Take credit card payments at your business with MONEI</Trans>
          </SectionHeader>
          <Trans parent="p">
            Whether you have an{' '}
            <PrismicPage slug="payment-method-integrations-ecommerce-platforms">
              online shop
            </PrismicPage>
            , multiple{' '}
            <InternalPageLink slug="monei-pay/retail-POS-system">
              brick-and-mortar locations
            </InternalPageLink>
            , a{' '}
            <InternalPageLink slug="monei-pay/qr-code-payment-restaurant">
              restaurant
            </InternalPageLink>
            , or need to charge customers for{' '}
            <InternalPageLink slug="monei-pay/pos-for-freelancers">your services</InternalPageLink>{' '}
            on the go, MONEI is here to help.
          </Trans>
          <br />
          <Trans parent="p">
            <Bold>Accept credit cards online</Bold>
          </Trans>
          <Trans parent="p">
            <AnchorLink href="https://docs.monei.com/docs/payment-methods/card/">
              Add credit card
            </AnchorLink>{' '}
            paymentss to all major e-commerce platforms with a simple plugin, or integrate with your
            custom-built website using our
            <AnchorLink href="https://docs.monei.com/api/#tag/Payments"> Payments API</AnchorLink>.
          </Trans>
          <br />
          <Trans parent="p">
            <Bold>Accept physical card payments</Bold>
          </Trans>
          <Trans parent="p">
            Download the <InternalPageLink slug="monei-pay">MONEI Pay</InternalPageLink> app (
            <AndroidDownloadLink>Android</AndroidDownloadLink> or{' '}
            <IosDownloadLink>iOS</IosDownloadLink>) to take credit{' '}
            <BlogLink slug="accept-card-payments-on-phone">card payments from your phone</BlogLink>{' '}
            and keep an eye on your omnichannel transaction history from anywhere.
          </Trans>
          <SectionActions>
            <SignUpButton variant="dark">
              <Trans>Open an Account</Trans>
            </SignUpButton>
          </SectionActions>
        </Section>
        <Partners />
      </Content>

      <Content>
        <Section centered column>
          <FaqsSection faqs={faqs} title={<Trans>Credit cards FAQ</Trans>} />
        </Section>
      </Content>
      {popularPosts.length > 0 && (
        <>
          <Content>
            <Section sx={{paddingBottom: '0px'}}>
              <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                <SectionHeader underline sx={{textAlign: 'center'}}>
                  <Trans>Latest news about credit card payments</Trans>
                </SectionHeader>
              </div>
            </Section>
          </Content>
          <RelatedBlogPosts posts={popularPosts} />
        </>
      )}
    </>
  );
};

export default CreditCards;

export const query = graphql`
  query ($language: String!, $prismicLang: String) {
    locales: allLocale(filter: {ns: {in: ["common", "credit-cards"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    prismic: prismic {
      allCategorys(lang: $prismicLang) {
        edges {
          node {
            title
            _meta {
              uid
              lang
            }
            order
          }
        }
      }
    }
    popularPosts: prismic {
      allPosts(
        sortBy: meta_lastPublicationDate_DESC
        tags: "Tarjetas"
        lang: $prismicLang
        first: 4
      ) {
        edges {
          node {
            title
            image
            summary
            _meta {
              uid
            }
          }
        }
      }
    }
  }
`;
